'use strict'

const {CONTROLLER_STAGE_DATA: {VISIBILITY}} = require('../common/constants')
const _ = require('lodash')

function isControllerVisible(controllerStageData, appManifestExists) {
    const visibility = calcVisibility(controllerStageData, appManifestExists)

    return visibility === VISIBILITY.DEV
}

function calcVisibility(controllerStageData, appManifestExists) {
    if (!appManifestExists) {
        return VISIBILITY.NEVER
    }

    const visibility = _.get(controllerStageData, 'visibility')

    if (!VISIBILITY[visibility]) {
        return VISIBILITY.DEV
    }

    return visibility
}

module.exports = {
    isControllerVisible
}
