'use strict'
const filters = require('./svgFiltersTemplates')
const getters = require('./svgFiltersGetters')

module.exports = {
    getFilter: getters.getFilter.bind(null, filters),
    getFilterNames: getters.getFilterNames.bind(null, filters),
    getFilterDefaults: getters.getFilterDefaults.bind(null, filters),
    isFilterExists: getters.isFilterExists.bind(null, filters)
}
